<template>
  <div>
    <div class="search-form">
      <el-button class="black-btn" @click="handleExport"  :loading="btnLoading">批量导出</el-button>
      <particulars-search @changeList="handleChangeList"></particulars-search>
    </div>
    <particulars-list @selectItems="getSelectedItems" ref="particulars"></particulars-list>
  </div>
</template>

<script>
import { particularsList, particularsSearch } from "../index";
import api from "@/api/stats";
export default {
  components: {
    particularsList: particularsList,
    particularsSearch: particularsSearch,
  },
  data() {
    return {
      selectIds: [],
      btnLoading:false
    };
  },
  methods: {
    handleExport() {
      if (!!this.selectIds.length) {
        this.btnLoading=true;
        let data = { ids: this.selectIds };
        api.downloadDetailExport(data).then((res) => {
          if (res.data.code === 10200) {
            const url = res.data.data.export_url;
            this.downloadFile(url);
            this.$nextTick(() => {
              this.$$success("已导出");
            });
          }
          this.btnLoading=false
        });
      } else {
        this.$$error("请先选中需要导出的数据");
      }
    },
    getSelectedItems(val) {
      this.selectIds = val.map((item) => {
        item.id = parseInt(item.id);
        return item.id;
      });
    },
    handleChangeList(params) {
        this.$refs.particulars.init(params);
    }
  },
//   mounted() {
//     this.$refs.particulars.init();
//   },
};
</script>
